import { useState, useEffect } from 'react';
import './App.css';

// function Card({notif}) {
//   return (
//     <div id="notification-card">
//       <p>{notif.notification.content.text}</p>
//       <span>{Date(notif.timestamp).toUTCString()}</span>
//     </div>
//   );
// }

function App() {
  const [notifData, setNotifData] = useState();
  const [message, setMessage] = useState();
  const [type, setType] = useState();

  // const n1 = [{
  //   "type": "success",
  //   "content": {
  //     "text": "Message 1"
  //   },
  //   "read": false,
  // },
  // {
  //   "type": "info",
  //   "content": {
  //     "text": "Message 2"
  //   },
  //   "read": true,
  // }]

  // const handleClick = () => {
  //   const putData = async (n) => {
  //     console.log(n)
  //     await fetch('api/notifications', {
  //       headers: {
  //         'Accept': 'application/json',
  //         'Content-Type': 'application/json'
  //       },
  //       method: 'POST',
  //       body: JSON.stringify(n)
  //     })
  //   }
  //   putData(n1);
  // }

  useEffect(() => {
    const getData = async () => {
      const resp = await fetch('api/notifications');
      const data = await resp.json();
      console.log('data received', data);
      setNotifData(data);
    }
    getData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage('');
    setType('');
    const putData = async () => {
      await fetch('api/notifications', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({type, content: {text: message}, read: false})
        })
    }
    putData();
  };

  return (
    <div className="App">
      <form id="notification-form" onSubmit={handleSubmit}>
        <label htmlFor="notification-message">Create Notification</label>
        <textarea
          id="notification-message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />

        <label htmlFor="notification-type">Notification Type:</label>
        <select
          id="notification-type"
          value={type}
          onChange={(e) => setType(e.target.value)}
          required
        >
          <option value="">Select Type</option>
          <option value="alert">Alert</option>
          <option value="info">Info</option>
          <option value="success">Success</option>
        </select>

        <button
          type="submit"
          id="send-notification-btn"
        >
          Send
        </button>
      </form>
      {/* <div id="notification-feed">
        {notifData.map(n => {
          <Card notif={n}></Card>
        })}
      </div> */}
    </div>
  );
}

export default App;
